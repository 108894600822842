import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import DynamicTitle from '../components/dynamicTitle';
import GeneralHead from '../components/generalHead';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ParallaxCache from '../utils/ParallaxCache';
import componentGenerator from '../utils/componentGenerator';

const IndexPage = ({
  data: {
    page: {
      breadcrumb,
      components,
      disableIntercomChat,
      disableMobileIntercomChat,
      dynamicTitle,
      metaTags,
      slug,
      // tags,
    },
  },
  location,
}) => {
  const [period, setPeriod] = useState('monthly');
  const isDark =
    components &&
    ((components[1]?.background &&
      Boolean(components[1].background.toLowerCase() !== 'light')) ||
      components[1]?.__typename === 'DatoCmsHeroCarouselComponent');

  return (
    <ParallaxProvider>
      {typeof window !== 'undefined' && <ParallaxCache />}
      <Layout
        isDark={isDark}
        forceAnnouncement={components[0].announcement}
        location={location}
        disableIntercomChat={disableIntercomChat}
        disableMobileIntercomChat={disableMobileIntercomChat}
      >
        <DynamicTitle title={metaTags?.title} dynamicTitle={dynamicTitle} />
        {componentGenerator(
          components,
          slug,
          breadcrumb,
          isDark,
          period,
          setPeriod
        )}
      </Layout>
    </ParallaxProvider>
  );
};

export const Head = ({
  data: {
    page: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  query($id: String) {
    page: datoCmsResource(id: { eq: $id }) {
      title
      internalName
      isRecommended
      excerpt
      featuredImage {
        gatsbyImageData(
          imgixParams: {
            fm: "webp"
            auto: "compress"
            maxW: 1080
            fit: "clip"
            q: 35
          }
        )
      }
      publishDate
      slug
      announcement {
        id
        pageTitle
      }
      headHtml
      tags {
        name
      }
      indexable
      dynamicTitle: announcement {
        id
        pageTitle
        delayTrigger
      }
      metaTags {
        title
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      breadcrumb {
        id
        label
        link
      }
      schemaMarkup {
        id
        schema
      }
      disableIntercomChat
      disableMobileIntercomChat
      components {
        __typename
        ... on DatoCmsJobListingBlock {
          id
        }
        ... on DatoCmsHeaderComponent {
          ...datoCmsHeaderComponent
        }
        ... on DatoCmsComponentHero {
          ...datoCmsComponentHero
        }
        ... on DatoCmsAccordionComponent {
          ...datoCmsAccordionComponent
        }
        ... on DatoCmsGridComponent {
          ...datoCmsGridComponent
        }
        ... on DatoCmsBannerAdComponent {
          ...datoCmsBannerAdComponent
        }
        ... on DatoCmsSwitchbackComponent {
          ...datoCmsSwitchbackComponent
        }
        ... on DatoCmsTestimonialCarouselComponent {
          ...datoCmsTestimonialCarouselComponent
        }
        ... on DatoCmsCarouselComponent {
          ...datoCmsCarouselComponent
        }
        ... on DatoCmsCardDeckComponent {
          ...datoCmsCardDeckComponent
        }
        ... on DatoCmsConversionPanelComponent {
          ...datoCmsConversionPanelComponent
        }
        ... on DatoCmsPricingCardDeck {
          internalName
          savingTag
          pricingCards {
            internalName
            horizontal
            active
            cardTitle
            subhead
            monthlyPrice
            annualPrice
            listContent {
              id
              internalName
              heading
              subhead
              icon {
                internalName
                image {
                  gatsbyImageData(
                    imgixParams: {
                      fm: "webp"
                      auto: "compress"
                      maxW: 1080
                      fit: "clip"
                      q: 35
                    }
                  )
                  url
                }
              }
            }
            link {
              label
              url
            }
          }
        }
        ... on DatoCmsPricingBlockContainer {
          internalName
          blockSection {
            internalName
            sectionTitle
            subhead
            planPerks {
              internalName
              perkTitle
              tooltipContent
              perkBlocks {
                internalName
                perkIcon {
                  internalName
                  image {
                    gatsbyImageData(
                      imgixParams: { fm: "webp", auto: "compress", q: 35 }
                    )
                    url
                  }
                }
                perkText
              }
            }
            planBlock {
              pricingCards {
                internalName
                cardTitle
                annualPrice
                monthlyPrice
                link {
                  label
                  url
                }
                icon {
                  image {
                    gatsbyImageData(
                      imgixParams: { fm: "webp", auto: "compress", q: 35 }
                    )
                    url
                  }
                }
              }
            }
          }
        }
        ... on DatoCmsGraphic {
          id
          internalName
          fullImage {
            image {
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              url
            }
          }
          tabletImage {
            image {
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              url
            }
          }
          mobileImage {
            image {
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              url
            }
          }
        }
        ... on DatoCmsInvestorCardDeck {
          id
          internalName
          heading
          scrollId
          companyList {
            id
            name
            logo {
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              url
            }
          }
        }
        ... on DatoCmsIllustration {
          id
          internalName
          backgroundColor {
            hex
          }
          heading
          subhead
          graphic {
            internalName
            image {
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                ...GatsbyDatoCmsFluid
              }
              url
            }
          }
          caption
        }
        ... on DatoCmsGatedList {
          id
          internalName
          heading
          subhead
          backgroundColor {
            hex
          }
          listBlock {
            id
            heading
            subhead
            icon {
              image {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                url
              }
            }
            tagLabel
          }
          images {
            internalName
            image {
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                ...GatsbyDatoCmsFluid
              }
              url
            }
            backgroundImage {
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                ...GatsbyDatoCmsFluid
              }
              url
            }
          }
        }
        ... on DatoCmsModularContentComponent {
          id
          content {
            __typename
            ... on DatoCmsRichText {
              content
            }
            ... on DatoCmsForm {
              id
              form {
                formId
                heading
                internalName
                subhead
              }
            }
          }
        }
        ... on DatoCmsSocialProofComponent {
          id
          internalName
          heading
          headingKicker
          subhead
          variant
          pressMentions {
            name
            featuredArticle
            lightLogo {
              image {
                url
                gatsbyImageData(
                  height: 32
                  imgixParams: { fm: "webp", auto: "compress", q: 35 }
                )
              }
            }
            darkLogo {
              image {
                url
                gatsbyImageData(
                  height: 32
                  imgixParams: { fm: "webp", auto: "compress", q: 35 }
                )
              }
            }
          }
        }
        ... on DatoCmsComponentVideo {
          ...datoCmsComponentVideo
        }
        ... on DatoCmsComponentTestimonial {
          id
          internalName
          message: body
          date(formatString: "MMMM DD, YYYY")
          headline
          headingKicker
          variant
          shopType
          starCount
          geolocation
          switchedFrom
          reviewLocation
          isExternalReview
          originalReviewLink
          video {
            internalName
            youtubeUrl
            heading
            videoThumbnail {
              ...datoCmsWebsiteImage
            }
          }
          person {
            role
            internalName
            firstName
            lastName
            headshot {
              image {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                url
              }
            }
            company {
              name
            }
          }
        }
        ... on DatoCmsWebsiteForm {
          id
          internalName
          heading
          formId
        }
        ... on DatoCmsSingleInstanceComponent {
          id
          internalName
          variant
        }
        ... on DatoCmsComponentUseCase {
          ...datoCmsComponentUseCase
        }
        ... on DatoCmsComponentFundingBar {
          id
          heading
          theme
          fundingSources {
            id
            about
            companySize
            monotoneLogo {
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              url
              title
              fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
        ... on DatoCmsComponentHistory {
          ...datoCmsComponentHistory
        }
        ... on DatoCmsMetricsComponent {
          ...datoCmsMetricsComponent
        }
        ... on DatoCmsExitIntentComponent {
          ...datoCmsExitIntentComponent
        }
        ... on DatoCmsComponentTabbedSwitcher {
          ...datoCmsComponentTabbedSwitcher
        }
        ... on DatoCmsComponentAward {
          id
          internalName
          theme
          heading
          callToAction {
            ...datoCmsCallToAction
          }
          featuredContent {
            alt
            filename
            gatsbyImageData(
              imgixParams: {
                fm: "webp"
                auto: "compress"
                maxW: 1080
                fit: "clip"
                q: 35
              }
            )
            fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
              ...GatsbyDatoCmsFluid
            }
            url
          }
        }
        ... on DatoCmsSingleDataMigrationAnimation {
          ...datoCmsSingleDataMigrationAnimation
        }
        ... on DatoCmsComponentSocialMediaCallout {
          ...datoCmsComponentSocialMediaCallout
        }
        ... on DatoCmsComponentNewsletter {
          ...datoCmsComponentNewsletter
        }
        ... on DatoCmsComponentListing {
          ...datoCmsComponentListing
        }
        ... on DatoCmsFooterComponent {
          ...datoCmsFooterComponent
        }
        ... on DatoCmsVideoCarouselComponent {
          ...datoCmsVideoCarouselComponent
        }
        ... on DatoCmsComponentHeading {
          ...datoCmsComponentHeading
        }
        ... on DatoCmsScrollableAnimation {
          ...datoCmsScrollableAnimation
        }
        ... on DatoCmsComponentLinkList {
          ...datoCmsComponentLinkList
        }
        ... on DatoCmsComponentToolsCallout {
          ...datoCmsComponentToolsCallout
        }
      }
    }
  }
`;

export default IndexPage;
